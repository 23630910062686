<template>
  <div class="caseProject">
    <a-spin :spinning="spinning">
      <!--  病例状态,标题搜索-->
      <div class="search">
        <div>
          状态: &nbsp;<a-radio-group  @change="openChange" button-style="solid">
          <a-radio-button :value= 1 >
            全部
          </a-radio-button>
          <a-radio-button :value= 2 >
            已启用
          </a-radio-button>
          <a-radio-button :value= 3 >
            已禁用
          </a-radio-button>
        </a-radio-group>
        </div>
        <div style="margin-left: 100px">
          标题:  &nbsp;<a-input v-model="searchName" placeholder="输入病例标题" style="width: 210px" size="default" />
          <a-button style="margin-left: 10px" type="primary" @click="onSearch" >查询</a-button>
          <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button></div>
      </div>
      <!--  病例项目-->
      <div class="casesAdd">
        <div class="caseUpload" @click="()=>{this.addShow = true}">
          +新增
        </div>
        <div class="caseShow" v-for="(item,index) in caseList" :key="index">
          <router-link :to="{path: 'case', query: {id: item.id ,title : item.title,shcase: item.is_sh_case,link : item.admin_link}}">
          <div class="caseImg">
            <img style="width: 50px;height: 50px" :src="item.pic" alt="">
            &nbsp;&nbsp;<h4 style="overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2; ">
            {{item.title}}</h4>
          </div>
          </router-link>
          <div class="caseData">
            <div class="count">
              <router-link :to="{path: 'case', query: {id: item.id ,title : item.title,shcase: item.is_sh_case,link : item.admin_link}}">
              <p style="color: black">总数</p>
              <h4 style="color: dodgerblue">{{item.countSum}}</h4>
              </router-link>
            </div>
            <div class="count">
              <p style="color: black">已通过</p>
              <h4 style="color: rgba(112, 182, 3, 0.647058823529412)">{{ item.countPass }}</h4>
            </div>
            <div class="count">
              <p style="color: black">已驳回</p>
              <h4 style="color:rgba(217, 0, 27, 0.647058823529412)">{{ item.countReject }}</h4>
            </div><div class="count">
            <p style="color: black">待审核</p>
            <h4 style="color: orange">{{ item.countAudit }}</h4>
          </div>
          </div>

          <div class="caseData">
            <div>
              <span v-if="item.number === 0" style="font-size: 12px;color: #CCCCCC">目标/剩余:  无限制</span>
              <span v-else style="font-size: 12px;color: #CCCCCC">目标/剩余: &nbsp; {{ item.number }}/{{item.number -item.countSum}}</span>
            </div>
            <div>
              <a-switch v-model="item.is_open=== 1" checked-children="是" un-checked-children="否" @click="switchChange(item.id,item.is_open === 1? 0 : 1)"/>
            </div>
          </div>
          <a-divider style="margin: 5px 0 0 0 ;height: 1px; padding: 0" />
          <div class="caseBtn">
            <div class="btn" @click="editCase(item.id)">编辑</div>
            <a-divider type="vertical" />
            <div class="btn" v-if="item.open_range === 'PART'" @click="scopeChange(item.id)">设置人员</div>
            <div class="btn" v-if="item.open_range === 'ALL'" style="color: #cccccc">设置人员</div>
            <a-divider type="vertical" />
            <div class="btn" @click="ReasonChange(item.id)">原因</div>
            <a-divider type="vertical" />
            <div class="btn" v-if="item.open_hospital_range === 'PART'" @click="hospitalChange(item.id)">设置医院</div>
            <div class="btn" v-if="item.open_hospital_range === 'ALL'" style="color: #cccccc">设置医院</div>
<!--            <a-popconfirm-->
<!--                title="确定删除?"-->
<!--                ok-text="是"-->
<!--                cancel-text="否"-->
<!--                @confirm="delCase(item.id)"-->
<!--                @cancel="cancel"-->
<!--            >-->
<!--                          <div class="btn">删除</div>-->
<!--            </a-popconfirm>-->

          </div>
        </div>
      </div>
      <div style="width: 100%;height: 50px;float: left;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination v-model="Page.current"
                        :total="Page.total"
                        @change="PageChange" />
        </div></div>
      <!-------------------------  新增弹框--------------------------->
      <a-drawer
          title="新增"
          width="500"
          :visible="addShow"
          @close="()=>{this.addShow = false}"
      >
        <a-form-model style="min-height: 600px" :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
          <a-form-model-item style="margin-bottom: -10px" label="封面图" prop="img">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 90%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic" ref="pic" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="addForm.pic"
                    style="width: 95px;height:95px;"
                    :src="addForm.pic"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 200*200，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px" label="名称" prop="title">
            <a-input placeholder="输入病例名称" v-model="addForm.title"/>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="目标数量" prop="number">
            <a-input v-model="addForm.number" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="前端链接" prop="link">
            <a-input v-model="addForm.link" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="后台链接" prop="adminlink">
            <a-input v-model="addForm.adminlink" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px"  label="模版库可见范围" prop="openrange">
            <a-radio-group v-model="addForm.openrange">
              <a-radio value=ALL>
                全部可见
              </a-radio>
              <a-radio value=PART>
                部分可见
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="addForm.openrange==='ALL'" style="margin-bottom: 5px" label="目标医院" prop="open_hospital_range">
            <a-radio-group v-model="addForm.open_hospital_range" >
              <a-radio value=ALL>
                全部可见
              </a-radio>
              <a-radio value=PART>
                部分可见
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px"  label="是否审核图片" prop="isshpic">
            <a-radio-group v-model="addForm.isshpic" >
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px"  label="是否审核病例" prop="isshcase">
            <a-radio-group v-model="addForm.isshcase" >
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px"  label="是否打印" prop="isprint">
            <a-radio-group v-model="addForm.isprint" >
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px"  label="是否结算" prop="ispay">
            <a-radio-group v-model="addForm.ispay">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item   label="是否需医生认证" prop="iscertifyrecord">
            <a-radio-group v-model="addForm.iscertifyrecord">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!---------------------编辑------------------->
      <a-drawer
          title="编辑"
          width="500"
          :visible="editShow"
          @close="()=>{this.editShow = false}"
      >
        <a-form-model style="min-height: 600px"  :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
          <a-form-model-item style="margin-bottom: -10px"  label="封面图" prop="pic">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 90%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="editPic" ref="img" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.pic"
                    style="width: 95px;height:95px;"
                    :src="editForm.pic"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 200*200，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="名称" prop="title">
            <a-input v-model="editForm.title" placeholder="输入病例名称 "/>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="目标数量"  prop="number">
            <a-input v-model="editForm.number" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px"  label="前端链接" prop="link">
            <a-input v-model="editForm.link" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 10px" label="后台链接" prop="adminlink">
            <a-input v-model="editForm.adminlink" />
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px" label="模版库可见范围" prop="openrange">
            <a-radio-group v-model="editForm.openrange" >
              <a-radio value=ALL>
                全部可见
              </a-radio>
              <a-radio value=PART>
                部分可见
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="editForm.openrange==='ALL'" style="margin-bottom: 5px" label="目标医院" prop="open_hospital_range">
            <a-radio-group  v-model="editForm.open_hospital_range" >
              <a-radio value=ALL>
                全部可见
              </a-radio>
              <a-radio value=PART>
                部分可见
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px" label="是否审核图片" prop="isshpic">
            <a-radio-group  v-model="editForm.isshpic">
              <a-radio  :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px" label="是否审核病例" prop="isshcase">
            <a-radio-group v-model="editForm.isshcase">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px" label="是否打印" prop="isprint">
            <a-radio-group v-model="editForm.isprint">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item style="margin-bottom: 5px" label="是否结算" prop="ispay">
            <a-radio-group v-model="editForm.ispay">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item   label="是否需医生认证"  prop="iscertifyrecord">
            <a-radio-group v-model="editForm.iscertifyrecord">
              <a-radio :value=1>
                是
              </a-radio>
              <a-radio :value=0>
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!---------------------设置范围-------------------------->
      <a-drawer
          title="设置范围"
          width="900"
          :visible="scopeShow"
          @close="closeScope"
      >
        <div class="selectPersonnel" >
          <h3>选择人员</h3>
          <div style="display: flex;align-items: center;width: 50%;justify-content: space-between;margin: 5px 0 5px 0">
            <div> 手机号: &nbsp;<a-input v-model="tels" style="width:160px" placeholder="输入手机号 "/></div>
            <a-button type="primary"  @click="searchTel"> 查询</a-button>
          </div>
          <a-table :columns="columns" :data-source="telList" rowKey="id"   :pagination="false"
          >
        <span slot="operate" slot-scope="text,item">
          <a @click="addPeople(item.id)">选择</a>
        </span>
          </a-table>
        </div>
        <div class="selectedStaff" style="margin: 10px 0 10px 0">
          <div style="display: flex;justify-content: space-between">
            <h3>已选人员</h3>
            <a-button icon="file-excel" style="color: #42b983" @click="excelBtn"> 导出全部</a-button>
          </div>
          <a-table :columns="columns" :data-source="telItems" :rowKey="(record,index)=>{return index}"   :pagination="false"
          >
        <span slot="operate" slot-scope="text,item">
          <a @click="delPeople(item.quence)">删除</a>
        </span>
          </a-table>
          <div style="display: flex;justify-content: flex-end;right: 35px;margin-bottom: 30px;margin-top: 10px">
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          @change="peoplePage" />
          </div>
        </div>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="closeScope">
            取消
          </a-button>
          <a-button type="primary" @click="scopeOk">
            确认
          </a-button>
        </div>
      </a-drawer>

<!---------------------------设置驳回原因----------------------->
      <a-drawer
          title="设置驳回原因"
          width="600"
          :visible="reasonShow"
          @close="reasonClose"
      >
        <div style="display: flex;width: 90%;">
          <h3>原因</h3>
          <a-textarea v-model="reasonText" style="width: 85%;margin-left: 20px;" />
        </div>
        <div style="display: flex;width: 90%;justify-content: center;margin-top: 30px;margin-bottom: 40px">
          <a-button type="primary" @click="reasonOk">提交</a-button>
        </div>
        <a-table :columns="reasonColumn" :data-source="reasonData" :rowKey="(record,index)=>{return index}"   :pagination="false"
        >
        <span slot="operate" slot-scope="text,item,index">
          <a @click="reasonEdit(index)" style="margin-right: 10px">修改</a>
          <a @click="delReason(index)">删除</a>
        </span>
        </a-table>

        <a-modal
            title="驳回原因修改"
            :visible="reasonEditShow"
            @ok="reasonEditOk"
            @cancel="()=>{this.reasonEditShow = false}"
        >
          <div style="display: flex;width: 90%;">
            <h3>原因</h3>
            <a-textarea v-model="reasonTextEdit" style="width: 85%;margin-left: 20px;min-height: 100px" />
          </div>
        </a-modal>



      </a-drawer>
<!----------------------------设置医院-------------------------->
      <a-drawer
          title="设置医院"
          width="700"
          :visible="hospitalShow"
          @close="hospitalClose"
      >
        <div style="margin-bottom: 10px">
          <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter.native="$event.target.blur()"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <a-icon type="plus" /> <span style="font-size: larger">医院名称</span>
          </a-tag>
        </div>
        <div >
          <template  v-for="(tag, index) in tags">
            <a-tag :key="index"  style="margin: 10px 10px 10px 0;">
              <span style="font-size: 16px;font-weight: 500" >{{ tag.data}} </span><a-icon type="close" @click="delHospital(index)"/>
            </a-tag>
          </template>
        </div>



      </a-drawer>
    </a-spin>
  </div>

</template>

<script>
import {
  delCaseItem, delHospital,
  delPeople, delReason,
  getCaseId,
  getCaseItems,
  getCaseTel, getHospital,
  getPeopleId, getReason,
  postCaseItems,
  postPeople,
  putCaseItem,
  putCaseSwitch, putHospital, putReason, putReasonId
} from "@/service/CaseTemplate_api";
import {update} from "@/utils/update";

export default {
  name: "CaseProject",
  data(){
    return{
      spinning:false,
      tags: [],
      inputVisible: false,
      inputValue: '',
      reasonText:'',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }],
        number:[
          {required: true, message: '请输入数量', trigger: 'blur' }
        ],
        link:[
          { required: true, message: '请输入前端链接', trigger: 'blur' }
        ],
        adminlink:[
          { required: true,message: '请输入后台链接', trigger: 'blur' }
        ],
        pic:[
          { required: true, message: '请上传封面图',trigger: 'blur' }
        ],
        iscertifyrecord:[
          { required: true, message: '是否需医生认证',trigger: 'change' }
        ],
        ispay:[
          { required: true, message: '是否结算',trigger: 'change' }
        ],
        isprint:[
          { required: true, message: '是否打印',trigger: 'change' }
        ],
        isshcase:[
          { required: true, message: '是否审核病例',trigger: 'change' }
        ],
        isshpic:[
          { required: true, message: '是否审核图片',trigger: 'change' }
        ],
        openrange:[
          { required: true, message: '是否设置可见范围',trigger: 'change' }
        ],
        open_hospital_range:[
          { required: true, message: '是否设置目标医院',trigger: 'change' }
        ],
      },
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospital_name',
        },
        {
          title: '科室',
          dataIndex: 'hospital_offices',
        },
        {
          title: '职称',
          dataIndex: 'job_title',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      reasonColumn:[
        {
          title: '原因',
          dataIndex: 'meea',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
          align:'right'
        }
      ],
      reasonData:[
      ],
      addForm:{
        title:'',
        number: null,
        link: '',
        adminlink:'',
        openrange:'',
        isshpic:null,
        isshcase:null,
        iscertifyrecord:null,
        ispay:null,
        isprint:null,
        pic:'',
      },
      editForm:{

      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      addShow:false,
      editShow:false,
      scopeShow:false,
      reasonShow:false,
      reasonEditShow:false,
      hospitalShow:false,
      reasonTextEdit:'',
      searchName:'',
      Page: {
        total:0,
        current: 1,
        pageSize:10,
      },
      caseList:[
      ],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      tels:'',
      telList: [],
      telItems: [],
      caseId:'',
      Ids:'',
      openSearch:'',
      counts:[],
      num3:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "病历项目管理")
  },
  mounted() {
    this.getCaseItem()
  },
  compute(){

  },
  methods:{
    //获取病例项目
    async getCaseItem(pageNum,title,isopen){
      this.spinning = true
      const cases = await getCaseItems(pageNum,title,isopen)
      if (cases.code === 0){
        this.caseList = cases.data.rows
        this.Page.total = cases.data.countSum
      }
      this.spinning =false
    },
    //搜索人员
    async getCaseTels(tel){
      const res = await getCaseTel(tel)
      if (res.code === 0){
        this.telList = res.data
      }
    },
    //分页跳转
    PageChange(){
      this.getCaseItem(this.Page.current,this.searchName,this.openSearch)
    },
    //状态搜索
    openChange(e){
      switch (e.target.value){
        case 1:
          this.getCaseItem()
          this.openSearch = ''
          break;
        case 2 :
          this.getCaseItem(1,'',1)
          this.openSearch = 1
          break;
        case 3:
          this.getCaseItem(1,'',0)
          this.openSearch = 0
          break;
      }
    },

    //点击搜索
    onSearch(){
      this.getCaseItem(1,this.searchName)
    },
    //搜索重置
    searchCancel(){
      this.getCaseItem()
      this.searchName = ''
    },
    //搜索人员手机号
    async searchTel(){
      await this.getCaseTels(this.tels)
    },
    //导出选择人员表
    excelBtn(){
      const url = 'https://yun-new-admin-api.yunicu.com/v3/case/excel/' + this.caseId ;
      window.open(url)

    },
    //上传病例项目封面图
    async uploadPic(){
      let inputDOM = this.$refs.pic.files[0];
      const pic = inputDOM.size / 1024 < 500;
      if (!pic){
        this.$message.warning('上传图片大小不能超过500k')
      }else {
        const image =await update(inputDOM)
        if (image.code === 0){
          this.addForm.pic = image.data.url
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
    },
    //修改封面图
    async editPic(){
      let inputDOM = this.$refs.img.files[0];
      const pic = inputDOM.size / 1024 < 500;
      if (!pic){
        this.$message.warning('上传图片大小不能超过500k')
      }else {
        const image =await update(inputDOM)
        if (image.code === 0){
          this.editForm.pic = image.data.url
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
    },
    //确认增加
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if (valid){
          const add = await postCaseItems(this.addForm)
          if (add.code===0){
            this.$message.success('添加成功')
            //刷新列表
            await this.getCaseItem(this.Page.current)
            //清空输入框
            this.$refs.addItem.resetFields();
            //关闭对话框
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //范围设置
    async scopeChange(id){
      this.caseId = id
      this.scopeShow = true
      await this.getPeople(this.caseId)
    },
     hospitalChange(id){
      this.hospitalShow = true
      this.caseId = id
      this.getHospitalList(id)
    },
    async getHospitalList(id){
      const Info = await getHospital(id)
      this.tags = Info.data
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    //删除医院
    async delHospital(index){
      const Info =await delHospital(this.caseId,index)
      if (Info.code === 0){
        await this.getHospitalList(this.caseId)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败'+ Info.message)
      }
    },

    hospitalClose(){
      this.hospitalShow = false
      this.inputVisible= false
      this.tags = []
    },
    //设置医院添加
    async handleInputConfirm() {
      const inputValue = this.inputValue;
      if (inputValue === ''){
        this.$message.warning('医院名称不能为空')
      }else {
        const data = {
          hospital : inputValue
        }
        const Info = await putHospital(this.caseId,data)
        if (Info.code === 0){
          await this.getHospitalList(this.caseId)
          this.$message.success('设置成功')
          this.inputVisible= false
          this.inputValue= ''
        }
      }
    },
    //驳回原因添加
     ReasonChange(id){
      this.reasonShow = true
      this.caseId = id
      this.getReasonList(id)
    },
    async getReasonList(id){
      const Info = await getReason(id)
      this.reasonData = Info.data
    },
    //新增驳回原因
    async reasonOk(){
      if (this.reasonText === ''){
        this.$message.warn('驳回原因不能为空')
      }else {
        const data = {
          remark : this.reasonText
        }
        const Info = await putReason(this.caseId,data)
        if (Info.code === 0){
          this.$message.success('提交成功')
          await this.getReasonList(this.caseId)
          this.reasonText = ''
        }
      }
    },
    reasonClose(){
      this.reasonShow = false
      this.reasonData = []
    },
    //修改驳回原因
    reasonEdit(index){
      this.reasonEditShow = true
      this.reasonIndex = index
      this.reasonTextEdit = this.reasonData[index].meea
    },
    //修改驳回原因确认
    async reasonEditOk(){
      if (this.reasonTextEdit === ''){
        this.$message.warn('驳回原因不能为空')
      }else {
        const data = {
          remark : this.reasonTextEdit
        }
        const Info = await putReasonId(this.caseId, this.reasonIndex,data)
        if (Info.code === 0){
          this.$message.success('修改成功')
          await this.getReasonList(this.caseId)
          this.reasonEditShow = false
        }
      }
    },
    //删除驳回原因
    async delReason(index){
      const Info =await delReason(this.caseId,index)
      if (Info.code === 0){
        this.$message.success('删除成功')
        await this.getReasonList(this.caseId)
      }else {
        this.$message.error('删除失败'+ Info.message)
      }
    },
    //添加人员
    async addPeople(peopleId){
      this.Ids = peopleId
      console.log(this.Ids)
      const data = {
        dd : peopleId,
        id : this.caseId
      }
      const people = await postPeople(data)
      if (people.code === 0){
        this.$message.success('选择成功')

        await this.getPeople(this.caseId)
      }
    },
    //获取已选择的人员
     async getPeople(id,pageNum){
      const ids = await getPeopleId(id,pageNum)
      if (ids.code ===0){
        this.telItems = ids.data.rows.flat(Infinity)
        this.pagination.total = ids.data.countSum
      }
    },
    //选择人员分页跳转
    peoplePage(){
      this.getPeople(this.caseId,this.pagination.current)
    },
    //删除已选择人员
    async delPeople(e){
      const del =await delPeople(this.caseId,e)
      if (del.code === 0){
        this.$message.success('删除成功')
        const data = {
          id : this.caseId,
          ii : this.caseId
        }
        await this.getPeople(data)
      }
    },
    //修改弹窗
    async editCase(e){
      this.editId = e
      const res = await getCaseId(e)
      if (res.code===0){
        this.editForm = res.data[0]
        this.editShow = true
      }

    },
    //确认修改提交
    editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editId
          const edit = await putCaseItem(id,this.editForm)
          if (edit.code === 0){
            this.editShow=false
            this.$message.success("修改成功")
            this.$refs.editItem.resetFields();
            await this.getCaseItem(this.Page.current)
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //病例开关
    async switchChange(id,open){
      const data = {
        isopen : open
      }
      const cases = await  putCaseSwitch(id,data)
      if (cases.code === 0){
        await this.getCaseItem(this.Page.current)
        this.$message.success('修改成功')
      }
    },
    //添加人员弹窗确认
    scopeOk(){
      this.scopeShow = false
      this.telItems = []
      this.telList = []
      this.tels = ''
      this.pagination.total = 0
    },
    //添加人员弹窗取消
    closeScope(){
      this.scopeShow = false
      this.telItems = []
      this.telList = []
      this.tels = ''
      this.pagination.total = 0
    },

    //删除病例项目
    async delCase(e){
      const del = await  delCaseItem(e)
      if (del.code === 0 ){
        await this.getCaseItem()
        this.$message.success('删除成功')
      }
    },
    cancel(){
      this.$message.error('取消删除');
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.caseProject{
  margin: -15px -15px;
  background-color:rgba(240, 242, 245, 1);
  .search{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .casesAdd{
    margin:  auto;
    width: 98%;
    height: 100%;
    min-height:475px;
    .caseUpload{
      z-index:1;
      margin: 1%;
      border-radius: 3px;
      border: #DDDDDD 2px dashed;
      cursor: pointer;
      width: 23%;
      height: 187px;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .caseShow{
      border-radius: 3px;
      border:#DDDDDD  1px solid;
      background-color: white;
      width: 23%;
      float: left;
      height: 187px;
      margin: 1%;
      .caseImg{
        display: flex;
        padding: 10px;
      }
      .caseData{
        display: flex;
        padding:  0 10px;
        margin: 0 auto;
        justify-content: space-between;
        .count p{
          margin: 2px auto;
        }
      }
      .caseBtn{
        cursor: pointer;
        height: 31px;
        display: flex;
        padding: 0 !important;
        background-color:rgba(247, 249, 250, 1);
        justify-content: space-between;
        ::v-deep .ant-divider, .ant-divider-vertical{
          height: auto;
        }
        .btn{
          width:100%;
          display: flex;
          font-size: 11px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
